<template>
  <div>
    <div style="text-align:left; font-weight:bold; font-size: 20px;">
      Report Liste
    </div>


    <div>
      <b-table striped sticky-header hover :items="reportItems" :fields="reportFields" @row-clicked="openReport">
        <template v-slot:cell(actions)="row">
          <b-button :disabled="!row.item.configSettings" class="mx-1" size="sm" variant="outline-dark" @click="showConfigOptions(row.item)">
            <i class="fas fa-wrench"></i>
          </b-button>
          <b-button v-if="hasOnDeleteListener" class="mx-1" size="sm" variant="danger" v-on:click="onDeleteReport(row.item)">
            <i class="fas fa-trash"></i>
          </b-button>
        </template>
      </b-table>
    </div>
    <div v-if="reportItems.length === 0">
      Es wurden noch keine Reports erstellt
    </div>
  </div>
</template>

<script>
export default {
  name: 'report-list',
  props: {
    reports: Array,
  },
  computed: {
    reportItems() {
      return this.reports;
    },
    reportFields() {
      // TODO: define fields as props and configure these for the ml report response
      return [
        {
          key: 'openReport',
          label: '',
          sortable: false,
        },
        {
          key: 'name',
          label: 'Dokumentname',
          sortable: true,
        },
        {
          key: 'format',
          label: 'Dateiformat',
          sortable: true,
        },
        {
          key: 'creationDate',
          label: 'Erstellungsdatum',
          sortable: true,
        },
        {
          key: 'comment',
          label: 'Kommentar',
          sortable: true,
        },
        {
          key: 'actions',
          label: '',
          sortable: false,
        },
      ];
    },
    hasOnDeleteListener() {
      return !!this.$listeners?.deleteReport;
    },
  },
  methods: {
    openReport(report) {
      window.open(report.url, 'blank')
    },
    onDeleteReport(report) {
      this.$emit('deleteReport', report);
    },
    showConfigOptions(report) {
      console.log(report.configSettings);
    },
  },
}
</script>

<style scoped>

</style>
