<template>
  <div class="reportconfig-container">
    <h5 class="header">
      EINSTELLUNGEN
    </h5>

    <div v-for="option of options" class="config-entry" :key="option.key">
      <div class="header config-row">
        <span>{{ option.title }}</span>
        <div>
          <i v-if="!headerState[option.key]"
             class="fas fa-chevron-circle-down closed"
             v-on:click="headerState[option.key]=!headerState[option.key]"></i>
          <i v-else
             class="fas fa-chevron-circle-up opened"
             v-on:click="headerState[option.key]=!headerState[option.key]"></i>
        </div>
      </div>

      <div class="body" v-if="headerState[option.key]">
        <template v-for="subOpt of option.options">

          <template v-if="subOpt.type === 'select'">
            <div v-for="value of subOpt.values"
                 :key="option.key + '.' + subOpt.key + '.' + value.value"
                 class="content config-row"
                 v-bind:class="[(configState[subOpt.key] === value.value) ? '' : 'disabledColor']">
              <span>{{ value.title }}</span>
              <i class="fas"
                 v-bind:class="[(configState[subOpt.key] === value.value) ? 'fa-check-circle checked' : 'fa-circle']"
                 v-on:click="configState[subOpt.key] = value.value;optionSelected(subOpt.key)"></i>
            </div>
          </template>

          <template v-else-if="subOpt.type === 'text'">
            <div :key="option.key + '.' + subOpt.key" class="content">
              <b-form-input class="config-textfield"
                            size="sm"
                            v-model="configState[subOpt.key]"
                            type="text"
                            :placeholder="subOpt.placeholder"
                            :required="subOpt.required"
                            @blur="optionSelected(subOpt.key)"></b-form-input>
            </div>
          </template>

        </template>
      </div>

    </div>
  </div>
</template>

<script>
export default {
  name: 'reportconfig',
  props: {
    options: Array,
  },
  watch: {
    options(val, oldVal) {
      const { configState } = this.createState(val);
      this.configState = configState;
    },
  },
  data() {
    const { headerState, configState } = this.createState();
    return {
      headerState: { ...headerState },
      configState: { ...configState },
    };
  },
  computed: {},
  created() {
    this.configChanged();
  },
  methods: {
    createState(options) {
      if (!options) options = this.options;
      const headerState = Object.fromEntries(options.map(option => [ option.key, option.expanded ]));
      const configState = Object.fromEntries(
          options
              .reduce((acc, option) => acc.concat(option.options), [])
              .map(subOption => [
                subOption.key,
                subOption.currentValue ?? (
                    subOption.values
                        ? (subOption.values.find(val => val.default) ?? subOption.values[0])?.value
                        : null
                ),
              ]),
      );
      return { headerState, configState };
    },
    optionSelected(key) {
      const value = this.configState[key];
      this.$emit('optionChanged', key, value);
      this.configChanged();
    },
    configChanged() {
      this.$emit('configChanged', this.configState);
    },
  },
}
</script>

<style scoped>
.reportconfig-container {
  text-align: left;
}

.reportconfig-container > .header {
  padding: 3px 0;
}

.config-entry {
  margin-top: 7px;
  margin-bottom: 7px;
}

.header {
  text-align: left;
  font-weight: bold;
  border-bottom: solid 1px #ccc;
}

.config-entry > .body > .content {
  text-align: left;
  font-weight: normal;
  font-size: 14px;
  padding: 2px 10px;
}

.config-row {
  display: flex;
  flex-direction: row;
}

.config-row > span {
  flex: 1 1;
}

.config-textfield {
  border-top: none;
  border-left: none;
  border-right: none;
  border-bottom: 1px solid gray;
  outline: none;
  box-shadow: none;
  border-radius: 0px;
}

.form-control {
  line-height: 2rem; /* set height to 2rem, otherwise ä, ö, ü characters will not be displayed correctly */
}
</style>
