<template>
  <div class="app">
    <div class="header buttonheader">
      <div class="align-left">
      </div>
      <div class="align-center">

      </div>
      <div class="align-right"></div>
    </div>

    <div class="has-header">

      <b-row align-h="center">

        <!-- Employee Section -->
        <b-col col md="6" sm="6">
          <employeelist style="height: 300px; margin-bottom: 10px;"
                        :employees="this.employees"
                        :loading="this.loadingEmployees"
                        selection-color="rgba(229, 21, 80, .5)"
                        @employeeSelected="employeeSelected"/>

          <p class="mt-2 mb-2" style="font-family: DistrictProBold, sans-serif; text-align: left;">
            {{ selectedEmployeeIds.length }} von {{ employees.length }} Mitarbeiter ausgewählt
          </p>

          <div class="d-flex flex-row justify-content-between">
            <div>
              <b-btn variant="outline-dark" class="mr-2" v-on:click="selectAllEmployees()">alle auswählen</b-btn>
              <b-btn variant="outline-dark" v-on:click="selectAllEmployees(false)">alle abwählen</b-btn>
            </div>
            <div>
              <b-btn style="background-color: rgba(229, 21, 80, .85)"
                     :disabled="this.creatingReport || selectedEmployeeIds.length === 0"
                     @click="createReport">
                {{ this.creatingReport ? 'Report wird erstellt...' : 'Report erstellen' }}
              </b-btn>
            </div>
          </div>
        </b-col>

        <!-- Config Section -->
        <b-col col lg="5" md="6" sm="6">
          <reportconfig :options="reportConfigOptions" @configChanged="this.configChanged"/>
        </b-col>

      </b-row>


      <b-row class="justify-content-md-center" style="margin-top: 40px;">
        <b-col col lg="10" md="12" sm="12">
          <report-list :reports="this.reports"/>

          <!-- pagination section -->
          <div class="d-flex flex-row align-content-start align-items-center">
            <b-button-group>
              <b-btn :disabled="!hasPreviousPage" v-on:click="loadReports(this.currentReportPage + 1)">
                <i class="fa fa-chevron-left"></i>
              </b-btn>
              <b-btn>
                {{ this.currentReportPage }}
              </b-btn>
              <b-btn :disabled="!hasNextPage">
                <i class="fa fa-chevron-right"></i>
              </b-btn>
            </b-button-group>
            <span class="ml-3">Seite {{ currentReportPage }} von {{ lastReportPage }}</span>
          </div>
        </b-col>
      </b-row>

    </div>
  </div>
</template>

<script>
import Employeelist from '@/components/uicomponents/employeelist';
import Reportconfig from '@/components/uicomponents/reportconfig';
import ReportList from '@/components/uicomponents/report-list';

export default {
  name: 'PersonnelMasterSheet',
  components: { Employeelist, Reportconfig, ReportList },
  data() {
    return {
      reportConfig: {
        reportName: '',
      },

      loadingEmployees: true,
      loadingReports: true,
      creatingReport: false,

      employees: [],
      reports: [],

      currentReportPage: 1,
      lastReportPage: 1,
      hasPreviousPage: false,
      hasNextPage: true,
    };
  },
  watch: {
    '$store.state.companyId': function () {
      this.reload();
    },
  },
  mounted() {
    this.reload().catch(console.error);
  },
  computed: {
    authorizationHeader() {
      return { 'Authorization': 'Bearer ' + this.$store.state.jwt };
    },
    reportConfigOptions() {
      return [
        {
          title: 'Dokumentname',
          key: 'nameSection',
          expanded: true,
          options: [
            {
              key: 'reportName',
              type: 'text',
              currentValue: this.reportConfig.reportName,
              placeholder: 'Dokumentname eingeben',
              required: true,
            },
          ],
        },
        {
          title: 'Kommentar',
          key: 'commentSection',
          expanded: false,
          options: [
            {
              key: 'description',
              type: 'text',
              currentValue: this.reportConfig.description ?? '',
              placeholder: 'Kommentar eingeben',
              required: false,
            },
          ],
        },
        {
          title: 'Datumsformat der Kopfzeile',
          key: 'headerDate',
          options: [
            {
              key: 'headerDateFormat',
              type: 'select',
              currentValue: this.reportConfig.headerDateFormat,
              values: [
                { title: 'DD.MM.YYYY', value: 'dmY', default: true },
                { title: 'YYYY-MM-DD', value: 'Ymd' },
              ],
            },
          ],
        },
      ];
    },
    selectedEmployeeIds() {
      return this.employees.filter(emp => emp.isSelected).map(emp => emp.id);
    },
  },
  methods: {
    //
    // data fetching
    //

    reload: async function () {
      this.reportConfig = this.getConfigFromStorage() ?? {};

      this.reportYear = this.$helpers.getCurrentMonthMomentTZ().year();
      this.reportMonth = this.$helpers.getCurrentMonthMomentTZ().month() + 1;
      this.reportConfig.reportName = 'Personalstammblatt';

      this.loadingEmployees = true;
      this.loadingReports = true;
      this.loadEmployees().catch(console.error).finally(() => this.loadingEmployees = false);
      this.loadReports().catch(console.error).finally(() => this.loadingReports = false);
    },

    loadEmployees: async function () {
      const url = '/api/sec/employee/coredata';
      const params = {
        companyId: this.$store.state.companyId,
        basicData: true,
      };
      const response = await this.axios({
        method: 'GET',
        url,
        params,
        headers: this.authorizationHeader,
      });
      this.employees = response.data.data.map(emp => ({ ...emp, isSelected: true }));
      this.$forceUpdate();
    },

    loadReports: async function (page = this.currentReportPage) {
      this.currentReportPage = page;

      const url = '/api/sec/report';
      const params = {
        companyId: this.$store.state.companyId,
        ids: 1,
        reportType: 'personnelmastersheet',
        page,
      };
      const response = await this.axios({
        method: 'GET',
        url,
        params,
        headers: this.authorizationHeader,
      });
      const { prev_page_url, next_page_url, last_page, data } = response.data;
      this.hasPreviousPage = !!prev_page_url;
      this.hasNextPage = !!next_page_url;
      this.lastReportPage = last_page;
      this.reports = data.map(report => ({
        id: report.id,
        name: report.name,
        description: report.description,
        format: 'pdf', // report.format,
        creationDate: report.jobStarted, // report.creationDate,
        comment: report.description,
        url: `${ report.fileserverURL }/api/file?id=${ report.fileserverID }&accessToken=${ report.fileserverAccessToken }`,
        originalMLData: report,
        configSettings: [
          {
            key: 'test',
            title: 'Testeintrag',
            value: true,
          }
        ],
      }));
    },

    createReport() {
      const url = '/api/sec/report/create';
      const companyId = this.$store.state.companyId;
      const params = {
        companyId,
      };
      let config = Object.fromEntries(Object.entries(this.reportConfig).filter(([ k, v ]) => k !== 'name' && k !== 'description'));
      config = {
        ...config,
        companyId,
        employeeIds: this.selectedEmployeeIds,
      }
      const body = {
        reportType: 'personnelmastersheet',
        name: 'Personalstammblatt',
        description: this.reportConfig.description,
        username: this.getUsernameFromJWT(),
        config: config,
      };
      // console.log(url, params, body);
      this.creatingReport = true;
      const response = this.axios({
        method: 'POST',
        url,
        params,
        data: body,
        headers: this.authorizationHeader,
      });
      this.creatingReport = false;
    },

    //
    // ui events
    //

    changeSelection: function (method) {
      const reportName = 'Personalstammblatt';
      this.reportConfig = { ...this.reportConfig, reportName };
      // this.recalcEmployeeCounter++;
      // this.loadEmployees();
    },

    employeeSelected(employee, allSelected) {
      const selectedIds = new Set(allSelected.map(emp => emp.id));
      this.employees = this.employees.map(
        emp => ({ ...emp, isSelected: selectedIds.has(emp.id) }),
      );
    },

    selectAllEmployees(select = true) {
      this.employees = this.employees.map(emp => ({ ...emp, isSelected: select }));
    },

    configChanged(config) {
      this.reportConfig = config;
      localStorage.setItem('reportConfig_comp' + this.$store.state.companyId, JSON.stringify(config));
    },

    //
    // helpers
    //

    getConfigFromStorage(key = null) {
      let config = localStorage.getItem('reportConfig_comp' + this.$store.state.companyId);
      if (!config) return null;
      config = JSON.parse(config);
      return !key ? config : config[key];
    },

    getUsernameFromJWT() {
      try {
        const payload = JSON.parse(atob(this.$store.state.jwt.split('.')[1]));
        return payload.name;
      } catch (e) {
        return null;
      }
    },

  },
}
</script>

<style scoped>

</style>
