<template>
  <div class="employee-list-container">

    <div style="margin-bottom:8px;">
      <b-form-input id="filterEmployees"
                    class="employee-filter"
                    size="sm"
                    v-model="filter"
                    type="text"
                    placeholder="Mitarbeiter filtern"
                    required></b-form-input>
    </div>

    <div v-if="loading"
         style="width:100%; font-size:17px; line-height:300px; border: 1px solid darkgray;"
         class="text-center">
      <i class="fas fa-spinner"></i> Mitarbeiter werden aktualisiert
    </div>

    <div v-else class="employee-container">
      <div v-for="employee of filteredEmployees"
           :key="employee.id"
           class="content employee-item"
           :style="{'background-color' : employee.isSelected ? selectionColor + ' !important' : 'rgba(235, 235, 235,0.8)'}"
           v-on:click="toggleSelection(employee.id)">

        <div class="employee-image">
          <!--b-img v-bind:src="getImageUrl(employee)" fluid alt="" style=" cursor:pointer;"></b-img-->
        </div>
        <div class="employee-label" v-bind:style="{'color' : employee.isSelected ? '#404040 !important' : ''}">
          {{ employee.lastName }} {{ employee.firstName }}
        </div>

      </div>

    </div>
  </div>
</template>

<script>
export default {
  name: 'employeelist',
  props: {
    employees: Array,
    loading: Boolean,
    initAllSelected: {
      type: Boolean,
      default: () => true,
    },
    canSelectMultiple: {
      type: Boolean,
      default: () => true,
    },
    selectionColor: {
      type: String,
      default: () => 'rgba(207, 231, 152,1)',
    },
  },
  data() {
    return {
      employeeList: this.mapEmployees(this.employees),
      filter: '',
    };
  },
  computed: {
    filteredEmployees() {
      const filter = this.filter.trim().toLowerCase();
      if (filter === '') return this.employeeList;
      return this.employeeList.filter(
        (employee) => employee.firstName.toLowerCase().includes(filter) || employee.lastName.toLowerCase().includes(filter),
      );
    },
  },
  watch: {
    employees: function (newVal) {
      this.employeeList = this.mapEmployees(newVal);
    },
  },
  methods: {
    mapEmployees(employees) {
      if (!employees)
        return [];
      return employees.map((employee) => ({
        isSelected: this.initAllSelected,
        ...employee,
      }));
    },
    toggleSelection(employeeId) {
      let employee;
      this.employeeList = this.employeeList.map(
        (emp) => {
          if (emp.id === employeeId) {
            employee = { ...emp, isSelected: !emp.isSelected };
            return employee;
          } else if (!this.canSelectMultiple) {
            return { ...emp, isSelected: false };
          }
          return emp;
        },
      );
      this.$emit('employeeSelected', employee, this.employeeList.filter((emp) => emp.isSelected));
    },
  },
}
</script>

<style scoped>
.employee-list-container {
  text-align: left;
  font-weight: bold;
  display: flex;
  flex-direction: column;
}

.employee-filter {
  border: none;
  outline: none;
  box-shadow: 0 2px 0 -1px var(--contrast-3);
}

.employee-container {
  flex-grow: 1;
  border: 1px solid var(--contrast-3);
  overflow-y: scroll;
  overflow-x: hidden;
}

.employee-item {
  height: 36px;
  margin: 0.5% 1% 0.5% 1%;
  width: 98%;
  cursor: pointer;
}

.employee-image {
  float: left;
  width: 28px;
  height: 28px;
  overflow: hidden;
  margin-left: 10px;
  margin-right: 10px;
  margin-top: 4px;
  border-radius: 50px;
  cursor: pointer;
}

.employee-label {
  text-align: left;
  height: 36px;
  line-height: 36px;
  font-size: 13px;
  cursor: pointer;
}

.fas {
  animation: spin 1s linear infinite;
}

@keyframes spin {
  from {
    transform: rotate(-45deg);
  }
  to {
    transform: rotate(315deg);
  }
}
</style>
